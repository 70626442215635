import React from "react";
import {
  Cog6ToothIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { MenuItem } from "./Layout";

interface HeaderPopupProps {
  items: MenuItem[];
  activeItem: MenuItem;
  isOpen: boolean;
  handleLogout: () => {};
}

export default function HeaderPopup({
  items,
  activeItem,
  isOpen,
  handleLogout,
}: HeaderPopupProps) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className='popup'>
      <div className='popup-content'>
        <ul className='popup-sidebar'>
          {items.map((i: any, index: number) => {
            const isActive = i.name === activeItem.name;
            return (
              <li key={index} className={`${isActive && "active"} popup-item`}>
                <Link to={`${i.to}`}>
                  {" "}
                  {i?.icon}
                  <span>{i.name}</span>
                </Link>
              </li>
            );
          })}
        </ul>

        <Link to={`/settings`} className='popup-item'>
          <Cog6ToothIcon />
          <p>Settings</p>
        </Link>
        <div className='popup-item' onClick={handleLogout}>
          <ArrowRightOnRectangleIcon />
          <p>Sign Out</p>
        </div>
      </div>
    </div>
  );
}
