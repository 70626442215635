import React, {useEffect, useState} from "react";
import shave from 'shave';
import api, {endpoints} from "../../utils/api";
import {Badge} from "react-bootstrap";

interface ConvoListItemDTOProps {
    data: {
        name: string
        text: string
        unread: number
    }
    onClick: () => void
    isActiveChat: boolean
}

const ConversationListItem: React.FC<ConvoListItemDTOProps> = (props) => {
    const {name, text, unread} = props.data;
    const {isActiveChat} = props;

    const defaultPic = require('../../resources/blank-profile-pic.png')
    const groupPic = require('../../resources/group-pic.png')
    const [pic, setPic] = useState<string>("")

    useEffect(() => {
        shave('.conversation-snippet', 24)

        // picture shenanigans
        const g = async () => {
            try {
                const response = await api.get(`${endpoints.files}/user-picture?email=${name}`, {
                    responseType: "blob",
                })
                const newPic = response.data
                const imageURL = URL.createObjectURL(newPic)
                setPic(imageURL)
            } catch (error) {
                // set default pic if none available
                setPic(defaultPic)
            }
        }

        // set group pic
        if (!/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/g.test(name)) {
            setPic(groupPic)
        } else {
            g()
        }
    }, [])


    return (
        <>
            <div className={`conversation-list-item ${isActiveChat ? 'conversation-active' : ''}`}
                 onClick={props.onClick}>
                <div>
                    <img id="conversation-photo" src={pic} alt="conversation"/>
                    <div id="conversation-info">
                        <h1 id="conversation-title">{name}</h1>
                        <p className="conversation-snippet">{text === "null" ? "..." : text}</p>
                    </div>
                </div>
                <Badge id="conversation-badge">{unread === 0 ? null : unread}</Badge>
            </div>
        </>
    )
}

export default ConversationListItem