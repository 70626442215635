import { useContext, useEffect, useState } from "react";
import DisplayErrors from "../elements/DisplayErrors";
import FileShareForm from "./FileShareForm";
import Modal from "react-bootstrap/Modal";
import { fileShareDTO } from "../../models/fileModel";
import SharedStateContext from "./SharedStateContext";
import useFeedback from "../elements/customHooks/useFeedback";
import api, { endpoints } from "../../utils/api";


export default function FileShareModal(props: fileShareModalProps) {
    const [entity, setEntity] = useState<fileShareDTO>()
    const [errors, setErrors] = useState<string[]>([])
    const { done } = useFeedback()
    const { sharedState, setSharedState } = useContext(SharedStateContext)
    
    useEffect(() => {
        setEntity(props.entity)
    }, [])


    async function sendInvitation(parameters: fileShareDTO) {
        try {
         await api.post(endpoints.files + "/share", parameters)

            setSharedState(sharedState.concat(parameters.email).sort())

            done("The file has been successfully shared!", "success", 3000)
            props.handleClose(false)
        }
        catch (error) {
            if (error && error.response) {
                setErrors(error.response.data)
            }
        }
    }

    return(
        <>
            <Modal show={props.show} onHide={() => props.handleClose(false)} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Share file</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DisplayErrors errors={errors}></DisplayErrors>
                {entity ?
                    <FileShareForm model={entity}
                        onSubmit={(values) => sendInvitation(values)} 
                        handleClose={props.handleClose}></FileShareForm>
                    : null
                }
            </Modal.Body>
            </Modal>
        </>
    )
}

interface fileShareModalProps {
    entity: fileShareDTO;
    show: boolean;
    handleClose(value: boolean) : void;
}