import React, { useState } from "react";
import { ErrorMessage, Field } from "formik";
import { FloatingLabel, FormGroup } from "react-bootstrap";
import '../../styles/FormTextInput.scss';

export default function FormTextInput(props: formTextInputProps) {
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const inputType = props.type === 'password' && !showPassword ? 'password' : 'text';

    return (
        <FormGroup className="mb-3 input-with-icon">
            <FloatingLabel controlId={props.name} label={props.displayName}>
                <Field id={props.name} name={props.name} type={inputType}
                    className="form-control" />
            </FloatingLabel>
            {props.type === 'password' && (
                <span className="input-icon" onClick={togglePassword}>
                    {showPassword ? 
                        <i className="bi bi-eye-slash"></i> : 
                        <i className="bi bi-eye"></i>
                    }
                </span>
            )}
            <ErrorMessage name={props.name}>
                {(message: string) =>
                    <div className="text-danger">{message}</div>}
            </ErrorMessage>
        </FormGroup>
    );
}

interface formTextInputProps {
    name: string;
    displayName: string;
    type: 'text' | 'password';
}

FormTextInput.defaultProps = {
    type: 'text'
}
