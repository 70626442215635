import React, { useContext, useEffect, useRef, useState } from "react";
import Message from "./Message";
import Compose from "./Compose";
import api, { endpoints } from "../../utils/api";
import { Button } from "react-bootstrap";
import useFeedback from "../elements/customHooks/useFeedback";
import WebSocketContext, { WebSocketContextType } from "../contexts/WebSocketContext"
import { messageDTO, sendMessageDTO } from "../../models/messages.models";


const MessageList: React.FC<messageListProps> = (props) => {
    const { done } = useFeedback()

    const [messages, setMessages] = useState<messageDTO[]>([])

    const [isResponseMessages, setIsResponseMessages] = useState(false)

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [page, setPage] = useState<number>(1)

    const containerRef = useRef<HTMLDivElement | null>(null)
    const renameRef = useRef<HTMLInputElement>(null)
    const [groupHeader, setGroupHeader] = useState<boolean>(false)
    const [renameModal, setRenameModal] = useState<boolean>(false)

    const { messageReceivedSignalR, markChatRead } = useContext(WebSocketContext) as WebSocketContextType;

    useEffect(() => {
        if (messageReceivedSignalR != null) {
            var scrolledToBottom = false;
            if (containerRef.current) {
                scrolledToBottom = Math.abs(containerRef.current.scrollHeight - containerRef.current.clientHeight - containerRef.current.scrollTop) <= 1;
            }

            receiveMessage(messageReceivedSignalR)

            if (scrolledToBottom) {
                scrollToBottom();
            }
        }

    }, [messageReceivedSignalR])

    const receiveMessage = async (message: messageDTO) => {
        setMessages((prevMessages) => [...prevMessages, message]);
        setTimeout(() => {
            markChatRead(props.chatId)
        }, 100);
    }

    useEffect(() => {
        const controller = new AbortController();

        //reset all messages
        setMessages([])

        //load first page of messages
        !isResponseMessages && getMessages(1, controller)

        if (props.participants.length > 2) {
            setGroupHeader(true)
        }

        setTimeout(() => {
            markChatRead(props.chatId)
        }, 100)

        scrollToBottom();

        return () => {
            controller.abort()
        }
    }, [containerRef.current])

    const scrollToBottom = () => {
        setTimeout(() => {
            if (containerRef.current) {
                const scrollHeight = containerRef.current.scrollHeight
                containerRef.current.scrollTo(0, scrollHeight)
            }
        }, 1200)
    }

    const getMessages = async (page: number, controller?: AbortController) => {
        try {
            const response = await api.get(`${endpoints.chats}/messages/${props.chatId}?Page=${page}&PageSize=50`, { signal: controller && controller.signal })
            if (response.data.length < 50) {
                // setHasMore(false)
                // edge case empty array
                if (response.data.length === 0) {
                    setIsLoading(false)
                    return
                }
            }

            // update local messages to read
            let updatedMessages = response.data.map((message: messageDTO) => ({
                ...message,
                isRead: true,
            })).reverse()
            setMessages((prevMessages) => [...updatedMessages, ...prevMessages])
            setIsResponseMessages(true)

            setIsLoading(false)
        } catch (e) {
            console.error("Can't keep up with the tea: ", e)
            setIsLoading(false)
        }
    }

    const handleScroll = async () => {
        try {
            if (containerRef.current && containerRef.current.scrollTop === 0) {
                await getMessages(page + 1)
                setPage((prevPage) => prevPage + 1)
            }
        } catch (e) {
            console.error("Scroll load failed:", e)
        }
    }

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", handleScroll)
        }
        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener("scroll", handleScroll)
            }
        }
    }, [])

    const renameGroup = () => {
        if (renameModal) {
            if (renameRef.current?.value !== props.name && renameRef.current?.value !== "") {
                const g = async () => {
                    try {
                        await api.post(endpoints.chats + '/name', {
                            "chatId": props.chatId,
                            "name": renameRef.current!.value
                        })
                        done("Set new name successfully!", "success", 3000)
                    } catch (e) {
                        console.error("Nah:", e)
                    }
                }
                g()
                renameRef.current!.value = ""
            }
            setRenameModal(false)
        } else {
            setRenameModal(true)
        }
    }

    return (
        <>
            <div id={groupHeader ? "group-message-list" : "message-list"}>
                {groupHeader ?
                    <div id="group-header">
                        <div id="group-credentials">
                            {renameModal ? <input id="group-title" placeholder={props.name} ref={renameRef}></input> :
                                <p id="group-title">{props.name}</p>}
                            <p id="group-participants">{props.participants.toLocaleString()}</p>
                        </div>
                        <Button id="group-rename"
                            onClick={renameGroup}>{renameModal ? "Set name" : "Rename Group"}</Button>
                    </div> : null}
                <div id="message-list-container" ref={containerRef}>
                    {isLoading ? (
                        <p>Loading ...</p>
                    ) : (
                        messages.map((message, index) => (
                            <Message key={index}
                                message={message}
                                prev={index > 0 ? messages[index - 1] : null}
                                next={index < messages.length - 1 ? messages[index + 1] : null}
                                groupMessage={groupHeader} />
                        ))
                    )}
                </div>
            </div>
            <Compose id={props.chatId} setMessages={setMessages} messages={messages} scrollRef={containerRef} />
        </>
    )
}

export default MessageList

type messageListProps = {
    name: string
    chatId: string
    participants: string[]
}