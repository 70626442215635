import { adjustByteSize } from '../../models/fileModel';

interface FilesListItemProps {
    imgName: string;
    imgSize: number;
    imgId: string
    getFileBlobHandler: (fileId: string) => void
}
const FilesListItem: React.FC<FilesListItemProps> = ({ imgName, imgSize, imgId, getFileBlobHandler }) => {
    return (
        <div className="files-list-item-wrapper" onClick={() => getFileBlobHandler(imgId)}>
            <div className="files-list-item-name">
                <span>{imgName}</span>
            </div>
            <div className="files-list-item-size">
                <span>{adjustByteSize(imgSize)}</span>
            </div>
        </div>
    );
};

export default FilesListItem;
