import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from "formik";
import Button from "react-bootstrap/Button";
import FormTextInput from "../elements/FormTextInput";
import { fileRenameDTO } from '../../models/fileModel';


export default function FileRenameForm(props: fileRenameFormProps) {
    
    return (
        <Formik
            initialValues={props.model}
            onSubmit={props.onSubmit}
            validationSchema={Yup.object({
                newName: Yup.string().required("This field is required"),
            })}>
            {(formikProps) => (
                <Form>
                    <input type="hidden" id='newName' name="newName" value={props.model.newName} />
                    <FormTextInput name="newName" displayName="Rename your file to:" />

                    <Button variant="primary" type="submit" disabled={formikProps.isSubmitting}>Submit</Button>
                    <Button variant="secondary" className="cancelButton" onClick={() => props.handleClose(false)}>Cancel</Button>
                </Form>
            )}
        </Formik>
    )
}

interface fileRenameFormProps {
    model: fileRenameDTO;
    onSubmit(values: fileRenameDTO, action: FormikHelpers<fileRenameDTO>): void;
    handleClose(value: boolean): void
}