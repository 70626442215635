import { useEffect, useRef } from "react";
import { Button, Form, Row } from "react-bootstrap";
import api, { endpoints } from "../../utils/api";
import useFeedback from "../elements/customHooks/useFeedback";
import * as Yup from 'yup';
import FormTextInput from "../elements/FormTextInput";
import { Formik } from "formik";

export default function RequestPasswordResetModel(props: PasswordResetPopupProps) {

    const { done } = useFeedback();
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // This function will be called whenever you click anywhere on the page
        function handleOutsideClick(event: MouseEvent) {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                props.closePopup();
            }
        }

        // Attach the click event listener
        document.addEventListener("mousedown", handleOutsideClick);

        // Return a cleanup function to be run when the component is removed
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [props]);

    // initiate mail request
    async function requestReset(mail: string) {
        try {
            const response = await api.put(`${endpoints.accounts}/password/request`, JSON.stringify(mail), {headers: {'Content-Type': 'application/json'}});
            if (response.status === 200) {
                done("Password reset request sent", "success", 3000)
                props.closePopup()
            }
        } catch (error) {
            done("Password reset request failed", "danger", 3000)
        }
    }

    const emailValidationSchema = Yup.object({
        email: Yup.string().required('Email is required').email('You have to insert a valid email'),
    });

    return (
        <>
            <div ref={modalRef}>
                <Formik
                    initialValues={{ email: "" }}
                    validationSchema={emailValidationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        requestReset(values.email).finally(() => setSubmitting(false));
                    }}
                >
                    {formikProps => (
                        <Form id="pwrp-container" onSubmit={formikProps.handleSubmit}>
                            <Row>
                                <Form.Group>
                                    <FormTextInput name="email" displayName="Email" />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Button type="submit" id="pwrp-sendBtn">
                                    Reset Password
                                </Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    )
}

interface PasswordResetPopupProps {
    closePopup(): void;
}