import React, { createContext, useEffect, useState, ReactNode, Dispatch, SetStateAction } from 'react';
import * as signalR from '@microsoft/signalr';
import { messageDTO, sendMessageDTO } from "../../models/messages.models";
import api, { endpoints } from '../../utils/api';
import useFeedback from '../elements/customHooks/useFeedback';

export interface WebSocketContextType {
    connection: signalR.HubConnection | null;
    messageReceivedSignalR: messageDTO | null;
    messageReadSignalR: string | null;
    setMessageReceivedSignalR: Dispatch<SetStateAction<messageDTO | null>>;
    setMessageReadSignalR: Dispatch<SetStateAction<string | null>>;
    sendMessage: (chatId: string, message: string) => void;
    markChatRead: (chatId: string) => void;
    newsCount: number | null;
    setNewsCount: Dispatch<SetStateAction<number | null>>;
}

const WebSocketContext = createContext<WebSocketContextType | null>(null);

interface WebSocketProviderProps {
    children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    const [messageReceivedSignalR, setMessageReceivedSignalR] = useState<messageDTO | null>(null);
    const [messageReadSignalR, setMessageReadSignalR] = useState<string | null>(null);
    const [newsCount, setNewsCount] = useState<number | null>(null);
    const { done } = useFeedback()

    useEffect(() => {
        const connect = async () => {
            const connection = new signalR.HubConnectionBuilder()
                .withUrl(endpoints.chatHub, { withCredentials: true })
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build()

            connection.on("ReceiveMessage", (message: messageDTO) => {
                setMessageReceivedSignalR(message)

                updateUnreadCount();

                done(`You have a new message from ${message.sender}: ${message.content}`, "success", 5000);
            });

            connection.start().then(() => {
            }).catch((err: any) => console.error(err.toString()));

            setConnection(connection);
        };

        connect();

        return () => {
            if (connection) {
                connection.stop();
            }
        };
    }, []);


    const sendMessage = (chatId: string, message: string) => {
        if (connection?.state === signalR.HubConnectionState.Connected) {
            const sendMessage: sendMessageDTO = {
                chatId: chatId,
                content: message
            }
            connection.invoke("SendMessage", sendMessage).catch((err: any) => console.error(err.toString()))
        }
    }

    const updateUnreadCount = () => {
        api.get(endpoints.chats + '/chats').then((response) => {
            const count = response;
            let counter = 0
            count.data.forEach((ele: { unreadMessages: number }) => {
                counter += ele.unreadMessages
            })
            setNewsCount(counter)
        })
    }

    const markChatRead = (chatId: string) => {
        if (connection) {
            connection.invoke("ReadMessage", chatId);
        }

        updateUnreadCount();
    }

    return (
        <WebSocketContext.Provider value={{
            connection,
            messageReceivedSignalR,
            messageReadSignalR,
            setMessageReceivedSignalR,
            setMessageReadSignalR,
            sendMessage,
            markChatRead: markChatRead,
            newsCount,
            setNewsCount
        }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export default WebSocketContext;