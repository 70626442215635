import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Header from './Header';
import { HeaderContainerProps } from './Headers.props';
import api, { endpoints } from '../utils/api';
import AuthenticationContext from './auth/AuthenticationContext';
import { useNavigate } from 'react-router-dom';
import { getEmptyBlob } from '../utils/formDataUtils';
import { useDebounce } from '../utils/useDebounce';
import { fileURLModel } from '../models/fileModel';

const HeaderContainer: React.FC<HeaderContainerProps> = React.memo(({
    user,
    items,
    activeItem,
    handleLogout,
}) => {
    const { foundedFiles, setFoundedFiles, textSearch, setTextSearch, blobURLState, setBlobURLState } = useContext(AuthenticationContext)

    const [ blobURL, setBlobURL ] = useState<fileURLModel>(getEmptyBlob())

    const [isOpenModal, setIsOpenModal] = useState(!!blobURLState.fileURL)

    const navigate = useNavigate()
    //Popup settings open/close logic
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const popupRef = useRef<HTMLDivElement | null>(null);

    // State for the search input value
    const [searchInput, setSearchInput] = useState('');

    // Use custom debounce hook for the search input
    const debouncedSearchTerm = useDebounce(searchInput, 500);

    useEffect(() => {
        if (debouncedSearchTerm) {
            // Call your search function here
            handleDebounceQuerySearch(debouncedSearchTerm);
        }
        // Make sure to include handleDebounceQuerySearch in the dependency array
        // if it's defined outside useEffect or make it stable with useCallback
    }, [debouncedSearchTerm]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextSearch(e.target.value);
        setSearchInput(e.target.value); // Set input value to state for debouncing
    };

    useEffect(() => {
        const closePopupOnOutsideClick = (event: MouseEvent) => {
            if (
                popupRef.current &&
                event.target instanceof HTMLElement &&
                !popupRef.current.contains(event.target)
            ) {
                closePopup();
            }
        };

        document.addEventListener('click', closePopupOnOutsideClick);

        return () => {
            document.removeEventListener('click', closePopupOnOutsideClick);
        };
    }, []);


    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    //Search logic
    const [isLoadingFiles, setIsLoadingFiles] = useState(false);

    const handleDebounceQuerySearch = async (inputValue: string) => {
        setIsLoadingFiles(true);
        try {
            const response = await api.get(endpoints.files + `/search?search=${inputValue}`);

            setFoundedFiles(response.data);
            setIsLoadingFiles(false);
        } catch (error) {
            console.error(error);
            setIsLoadingFiles(false);
        }
    };

    //Get img blob after Search for FileModal (preview)
    const getFileBlobHandler = async (fileId: string) => {
        const response = await api.get(endpoints.files + `/preview/${fileId}`, {responseType: "blob"})
        const responseFileBlob: Blob = response.data

        const fileURL = URL.createObjectURL(responseFileBlob)

        setBlobURL({fileURL: fileURL, type: responseFileBlob.type, blob: responseFileBlob})
        setBlobURLState({fileURL: fileURL, type: responseFileBlob.type, blob: responseFileBlob})
        
        navigate('/found_files')
        setIsOpenModal(true)
    }

    const onHideModalCallback = () => {
        setIsOpenModal(false)
        setTimeout(() => {
            setBlobURL(getEmptyBlob())
            setBlobURLState(getEmptyBlob())
        }, 500)
    }

    return (
        <>
            <Header
                user={user}
                items={items}
                activeItem={activeItem}
                handleLogout={handleLogout}
                //Popup props
                ref={popupRef}
                openPopup={openPopup}
                isPopupOpen={isPopupOpen}
                //Search props
                textSearch={textSearch}
                setTextSearch={handleChange}
                isLoadingFiles={isLoadingFiles}
                foundedFiles={foundedFiles}
                //getFileBlobHandler prop
                getFileBlobHandler={getFileBlobHandler}
            />
        </>
    );
});

export default HeaderContainer;
