import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Error404() {
    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            navigate('/')
        }, 1500)
    })
    
    return(
        <>
            <h1>404</h1>
            <h2>Page not found</h2>
        </>
    );
}