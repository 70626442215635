import { useEffect, useRef, useState } from "react"


const useClickOutside = () => {
    const [isOpen, setIsActive] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if(ref.current && !ref.current.contains(e.target as Node)) {
                setIsActive(false)
            }
        }
        function handleEscapeKey(e: KeyboardEvent) {
            if(e.key === "Escape") {
                setIsActive(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        document.addEventListener("keydown", handleEscapeKey)

        return() => {
            document.removeEventListener("mousedown", handleClickOutside)
            document.removeEventListener("keydown", handleEscapeKey)
        }
    }, [])

    return { isOpen, setIsActive, ref}
}

export default useClickOutside