import { Button } from "react-bootstrap";
import { sharedFileDTO } from "../../models/fileModel"
import { useContext, useEffect, useState } from "react";
import SharedStateContext from "./SharedStateContext";
import useClickOutside from "./useClickOutside";
import FileRenameModal from "./FileRenameModal";
import useFeedback from "../elements/customHooks/useFeedback";
import api, { endpoints } from "../../utils/api";


interface subFileProps {
    file: sharedFileDTO;
    isOpen: boolean;
    toggleMenu: () => void;
    stopShare?: (fileShareId: string) => Promise<void>
}


export default function SharedFileSubbuttons({file, isOpen, toggleMenu, stopShare}: subFileProps) {
    const [showRenameModal, setShowRenameModal] = useState<boolean>(false)
    const { setMenuItems } = useContext(SharedStateContext)
    const { isOpen: isActive, setIsActive, ref} = useClickOutside()
    const { done } = useFeedback()

    // see file variant for explanation
    useEffect(() => {
        setIsActive(isOpen)
        
    }, [isOpen, setIsActive])

    useEffect(() => {
        setMenuItems([
            { label: "Rename", action: () => setShowRenameModal(true), variant: "light", icon: "bi-vector-pen"},
            { label: "Download", action: () => downloadFile(file), variant: "primary", icon: "bi-download"},
            { label: "Cancel", action: () => stopShare && stopShare(file.fileId), variant: "danger", icon: "bi-share-fill"},
        ])
    }, [])


    function downloadFile(file: sharedFileDTO) {
        api({
            url: `${endpoints.files}/download/${file.fileId}`,
            method: 'GET',
            responseType: "blob"
        })
            .then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data)

                // create anachor HTLM element with href to file & click
                const link = document.createElement('a')
                link.href = href
                link.setAttribute('download', file.name)
                document.body.appendChild(link)
                link.click()

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link)
                URL.revokeObjectURL(href)
            })
    }

    return(
        <>
            <div ref={ref}>
                <Button
                    variant="secondary"
                    id="fl-options"
                    onClick={toggleMenu}>
                        <i className="bi bi-three-dots" />
                </Button>
                {isActive ?
                    <div id="fl-option-btns">
                        <Button variant="light" onClick={() => setShowRenameModal(true)}><i className="bi bi-vector-pen"></i> Rename</Button>
                        <Button variant="primary" onClick={() => downloadFile(file)}><i className="bi bi-download"></i> Download</Button>
                        <Button variant="danger" onClick={() => stopShare && stopShare(file.fileId)}><i className="bi bi-share-fill"></i> Remove</Button>
                    </div> : null}
            </div>

            {showRenameModal ?
                <FileRenameModal
                show={showRenameModal}
                handleClose={(value) => setShowRenameModal(value)}
                file={{"fileId": file.fileId, "newName": file.name}} />
            : null}
        </>
    )
}