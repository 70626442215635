import { DateTime } from "luxon";
import { useContext, useEffect, useRef } from "react";
import AuthenticationContext from "../auth/AuthenticationContext";

interface MessageProps {
    message: messageDTO;
    prev: messageDTO | null;
    next: messageDTO | null;
    groupMessage: boolean;
}

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export default function Message(props: MessageProps) {
    const { meRef } = useContext(AuthenticationContext)
    const isMine = props.message.sender === meRef.current
    const currentMoment = DateTime.fromISO(props.message.createdDate, { zone: 'utc' }).setZone(userTimeZone)
    const friendlyTimestamp = currentMoment.toLocaleString(DateTime.DATETIME_MED)
    const startsSequence = useRef(true)
    const endsSequence = useRef(true)
    const showTimestamp = useRef(true)


    useEffect(() => {
        if (props.prev !== null) {
            let previousMoment = DateTime.fromISO(props.prev.createdDate, { zone: 'utc' }).setZone(userTimeZone)
            let previousDuration = currentMoment.diff(previousMoment)
            let prevBySameAuthor = props.prev.sender === props.message.sender
            
            if (prevBySameAuthor && previousDuration.as('hours') < 1) {
                startsSequence.current = false
            }
            if (previousDuration.as('hours') < 1) {
                showTimestamp.current = false
            }
        }

        if (props.next !== null) {
            let nextMoment = DateTime.fromISO(props.next.createdDate, { zone: 'utc' }).setZone(userTimeZone)
            let nextDuration = nextMoment.diff(currentMoment)
            let nextBySameAuthor = props.next.sender === props.message.sender
            
            if (nextBySameAuthor && nextDuration.as('hours') < 1) {
                endsSequence.current = false
            }
        }
    })
    
    
    return (
        <>
        <div id={`message${isMine ? '-mine' : ''}${startsSequence.current ? '-start' : ''}${endsSequence.current ? '-end' : ''}`} >
            { showTimestamp.current ? <div id="timestamp">{ friendlyTimestamp }</div> : null }
            { props.groupMessage && !isMine ? <div id="group-sender">{props.message.sender}</div> : null}
            <div id="bubble-container">
                <div id="bubble" title={friendlyTimestamp}>
                    { props.message.content }
                </div>
            </div>
        </div>
        </>
    )
}


interface messageDTO {
    chatId: string
    sender: string
    content: string
    createdDate: string
    isRead: boolean
}