import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Button, Badge } from 'react-bootstrap';
import EntityIndex from './elements/EntityIndex';
import SharedFileSubbuttons from './files/SharedFileSubbuttons';
import { DateTime } from 'luxon';
import useFeedback from './elements/customHooks/useFeedback';
import '../styles/SharedFiles.scss';
import api, { endpoints } from '../utils/api';
import { adjustByteSize, sharedFileDTO } from '../models/fileModel';
import Layout from './Layout';
import { truncateFileName } from '../utils/truncateFileName';

// @ts-ignore
import Table from 'react-table-lite';
import FileContentViewer from './files/FileContentViewer';
import { getEmptyBlob } from '../utils/formDataUtils';
import { fileURLModel } from '../models/fileModel';

export default function SharedFilesPage() {
    const [openMenuId, setOpenMenuId] = useState<string | null>('');
    const [newRequests, setNewRequests] = useState<number>(0);
    const [reloading, setReloading] = useState<boolean>(false);
    const { done } = useFeedback();
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');

    const [blobURL, setBlobURL] = useState<fileURLModel>(getEmptyBlob());
    const [isFileViewerOpen, setIsFileViewerOpen] = useState(false);

    const toggleMenu = (menuId: string) => {
        setOpenMenuId((prevId) => (prevId === menuId ? null : menuId));
    };

    const fetchNewRequestsCount = async () => {
        try {
            const response = await api.get(`${endpoints.files}/shared/pending`);
            setNewRequests(response.data.length);
        } catch (error) {
            console.error('Error fetching new requests count: ', error);
        }
    };

    useEffect(() => {
        fetchNewRequestsCount();
        const interval = setInterval(fetchNewRequestsCount, 60000); // Fetch every 60 seconds
        return () => clearInterval(interval);
    }, [reloading]);

    async function requestHandling(id: string, decision: string) {
        api.post(endpoints.files + `/share/${decision}`, JSON.stringify(id), {
            headers: { 'Content-Type': 'application/json' },
        }).then(() => {
            setReloading(!reloading);

            if (decision === 'accept') {
                done('Request accepted', 'success', 3000);
            } else {
                done('Request denied', 'danger', 3000);
            }
        }).catch((error) => {
            console.error('Nope: ', error);
        });
    }

    async function stopShare(fileShareId: string) {
        api.post(endpoints.files + '/share/decline', JSON.stringify(fileShareId), { headers: { 'Content-Type': 'application/json' } })
            .then(() => {
                done('Stopped file share.', 'success', 3000);
                setReloading(!reloading);
            })
            .catch((error) => {
                console.error('Did NOT stop share: ', error);
            });
    }

    const getFileBlobHandler = async (fileId: string) => {
        const response = await api.get(endpoints.files + `/preview/${fileId}`, {responseType: "blob"})
        const responseFileBlob: Blob = response.data;
        const fileURL = URL.createObjectURL(responseFileBlob);
        setBlobURL({ fileURL, type: responseFileBlob.type, blob: responseFileBlob });
        setIsFileViewerOpen(true);
    };

    const onHideFileViewer = () => {
        setIsFileViewerOpen(false);
        setTimeout(() => setBlobURL(getEmptyBlob()), 500);
    };

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
        <Layout>
            <Tabs defaultActiveKey="files">
                <Tab eventKey="files" title="Accepted Fileshares">
                    <div id="shared-file-list">
                        <EntityIndex<sharedFileDTO>
                            sortBy={sortBy}
                            sortOrder={sortOrder}
                            entityName="SharedFiles"
                            title=""
                            url={`${endpoints.files}/shared`}
                            requestPending={false}
                            newLoadFile={reloading}
                        >
                            {(files, _, sortItems) => {
                                return (
                                    <Table
                                        data={files}
                                        headers={['name', 'owner', 'createdDate', 'size', 'actions']}
                                        customHeaders={{
                                            'name': 'Name',
                                            'owner': 'Owner',
                                            'createdDate': 'Date',
                                            'size': 'Size',
                                            'actions': ' '
                                        }}
                                        customRenderCell={{
                                            'name': (file: sharedFileDTO) => {
                                                return (
                                                    <span
                                                        onClick={() => getFileBlobHandler(file.fileId)}
                                                        style={{ cursor: 'pointer', color: '#176AD6', textDecoration: 'underline' }}
                                                    >
                                                        {truncateFileName(file.name, 50)}
                                                    </span>
                                                );
                                            },
                                            'owner': (file: sharedFileDTO) => {
                                                return file.owner;
                                            },
                                            'size': (file: sharedFileDTO) => {
                                                return adjustByteSize(file.size);
                                            },
                                            'createdDate': (file: sharedFileDTO) => {
                                                return DateTime
                                                    .fromISO(file.createdDate, { zone: 'utc' }).setZone(userTimeZone)
                                                    .toLocaleString(DateTime.DATETIME_MED);
                                            },
                                            'actions': (file: sharedFileDTO) => {
                                                return (
                                                    <div id="fl-item">
                                                        <SharedFileSubbuttons
                                                            file={file}
                                                            isOpen={openMenuId === file.fileId}
                                                            toggleMenu={() => toggleMenu(file.fileId)}
                                                            stopShare={stopShare}
                                                        />
                                                    </div>
                                                );
                                            }
                                        }}
                                        sortBy={['name', 'createdDate', 'size', 'owner']}
                                        onSort={(e: Event, _: sharedFileDTO[], columnName: string, order: string) => {
                                            setSortBy(columnName);
                                            setSortOrder(order);
                                            sortItems(columnName, order);
                                        }}
                                        cellStyle={{ position: 'relative' }}
                                    />
                                );
                            }}
                        </EntityIndex>
                    </div>
                </Tab>
                {newRequests > 0 && (
                    <Tab eventKey="requests" title={`Pending Requests (${newRequests})`}>
                        <div id="request-list">
                            <EntityIndex<sharedFileDTO>
                                entityName="SharedFileRequests"
                                title="Pending requests"
                                url={`${endpoints.files}/shared/pending`}
                                requestPending={true}
                                newLoadFile={reloading}
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                            >
                                {(files, _, sortItems) => (
                                    <Table
                                        data={files}
                                        headers={['name', 'owner', 'createdDate', 'size', 'actions']}
                                        customHeaders={{
                                            'name': 'Name',
                                            'owner': 'Owner',
                                            'createdDate': 'Date',
                                            'size': 'Size',
                                            'actions': ' '
                                        }}
                                        customRenderCell={{
                                            'name': (file: sharedFileDTO) => {
                                                return (
                                                    <span
                                                        onClick={() => getFileBlobHandler(file.fileId)}
                                                        style={{ cursor: 'pointer', color: '#176AD6', textDecoration: 'underline' }}
                                                    >
                                                        {truncateFileName(file.name, 50)}
                                                    </span>
                                                );
                                            },
                                            'size': (file: sharedFileDTO) => {
                                                return adjustByteSize(file.size);
                                            },
                                            'owner': (file: sharedFileDTO) => {
                                                return file.owner;
                                            },
                                            'createdDate': (file: sharedFileDTO) => {
                                                return DateTime
                                                    .fromISO(file.createdDate, { zone: 'utc' }).setZone(userTimeZone)
                                                    .toLocaleString(DateTime.DATETIME_MED);
                                            },
                                            'actions': (file: sharedFileDTO) => {
                                                return (
                                                    <div id="fl-item" style={{ display: 'flex', gap: '5px' }}>
                                                        <Button
                                                            onClick={() => requestHandling(file.fileId, 'accept')}
                                                            variant="success"
                                                            id="request-acceptance"
                                                            size="sm"
                                                        >
                                                            <i className="bi bi-check-circle-fill" />
                                                        </Button>
                                                        <Button
                                                            onClick={() => requestHandling(file.fileId, 'decline')}
                                                            variant="danger"
                                                            id="request-denial"
                                                            size="sm"
                                                        >
                                                            <i className="bi bi-x-circle-fill" />
                                                        </Button>
                                                    </div>
                                                );
                                            }
                                        }}
                                        sortBy={['name', 'createdDate', 'size', 'owner']}
                                        onSort={(e: Event, _: sharedFileDTO[], columnName: string, order: string) => {
                                            setSortBy(columnName);
                                            setSortOrder(order);
                                            sortItems(columnName, order);
                                        }}
                                        cellStyle={{ position: 'relative' }}
                                    />
                                )}
                            </EntityIndex>
                        </div>
                    </Tab>
                )}
            </Tabs>

            {isFileViewerOpen && (
                <FileContentViewer
                    blobURL={blobURL}
                    onHide={onHideFileViewer}
                />
            )}
        </Layout>
    );
}
