import css from '../../styles/DisplayErrors.module.scss';

export default function DisplayErrors(props: displayErrorProps) {
    return (
        <>
            {props.errors && props.errors.length > 0 && (
                props.errors.length === 1 ? (
                    <div className={css.errormessage}>{props.errors[0]}</div>
                ) : (
                    <ul className={css.errormessage}>
                        {props.errors.map((error, index) => <li key={index}>{error}</li>)}
                    </ul>
                )
            )}
        </>
    )
}

interface displayErrorProps {
    errors?: string[];
}