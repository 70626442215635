import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from "formik";
import Button from "react-bootstrap/Button";
import FormTextInput from "../elements/FormTextInput";
import { fileShareDTO } from '../../models/fileModel';


export default function FileShareForm(props: fileShareFormProps) {
    
    return (
        <Formik
            initialValues={props.model}
            onSubmit={props.onSubmit}
            validationSchema={Yup.object({
                email: Yup.string().email().required("This field is required"),
            })}>
            {(formikProps) => (
                <Form>
                    <input type="hidden" id="mail" name="email" value={props.model.email} />
                    <FormTextInput name="email" displayName="Share with following E-Mail" />

                    <Button variant="primary" type="submit" disabled={formikProps.isSubmitting}>Submit</Button>
                    <Button variant="secondary" className="cancelButton" onClick={() => props.handleClose(false)}>Cancel</Button>
                </Form>
            )}
        </Formik>
    )
}

interface fileShareFormProps {
    model: fileShareDTO;
    onSubmit(values: fileShareDTO, action: FormikHelpers<fileShareDTO>): void;
    handleClose(value: boolean): void
}