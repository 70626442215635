import React, { useEffect, useState } from 'react';
import ProfilePic from './elements/ProfilePic';
import logoNoTextBeta from '../resources/logo-no-text_beta.png';
import { MagnifyingGlassIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import HeaderPopup from './HeaderPopup';
import { HeaderProps } from './Headers.props';
import FilesListItem from './files/FilesListItem';
import { useNavigate } from 'react-router-dom';

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
    (
        {
            user,
            items,
            activeItem,
            handleLogout,
            //Popup props
            openPopup,
            isPopupOpen,
            //Search props
            textSearch,
            setTextSearch,
            foundedFiles,
            isLoadingFiles,
            //getFileBlobHandler prop
            getFileBlobHandler,
        },
        ref
    ) => {
        const navigate = useNavigate()

        const [isFocusInput, setIsFocusInput] = useState(false);

        useEffect(() => {
            //close logic dropdown (just close + after preview) 
              const handledCloseDropdown = (e: any) => {
                const composed = e.composedPath();

                if (!composed.some((el: any) => el.dataset?.closeim || el.className === 'fade modal')) {

                  setIsFocusInput(false);
                  document.body.removeEventListener('click', handledCloseDropdown);
                }
              };
        
              document.body.addEventListener('click', handledCloseDropdown);
          }, [isFocusInput]);

          const inputKeyPressedHandler = (key: string) => {
            if(key === 'Enter') {
                navigate('/found_files')
            }
          }

        return (
            <header className="header">
                <div data-closeim className="input-wrapper">
                    <div className="input-container">
                        <input
                            onFocus={() => setIsFocusInput(true)}
                            value={textSearch}
                            onChange={setTextSearch}
                            type="text"
                            placeholder={`Search with MUB`}
                            onKeyDown={(event) => inputKeyPressedHandler(event.key)}
                        />
                        {isFocusInput && (
                            <div className={'input-options-wrapper'}>
                                {foundedFiles.length ? (
                                    foundedFiles?.map((imgItem) => {
                                        return (
                                            <FilesListItem
                                                key={imgItem.id}
                                                imgName={imgItem.name}
                                                imgSize={imgItem.size}
                                                imgId={imgItem.id}
                                                getFileBlobHandler={getFileBlobHandler}
                                            />
                                        );
                                    })
                                ) : (
                                    <span>{!isLoadingFiles ? 'No options' : 'Loading...'}</span>
                                )}
                            </div>
                        )}
                    </div>
                    <MagnifyingGlassIcon className="h-6 w-6 text-gray-500" style={{cursor: 'pointer'}} onClick={() => inputKeyPressedHandler('Enter')}/>
                </div>
                <div className="user-wrapper">
                    <img className="user__logo-mobile" src={logoNoTextBeta} alt="logo" />
                    <div className="user__content">
                        <div className="user__data-wrapper">
                            <div className="user__text">
                                <span>
                                    {user?.firstName} {user?.lastName}
                                </span>
                                {/* TODO: Enable only when user is admin */}
                                {/* <p>Administrator</p> */}
                            </div>
                            <div className='user__settings' ref={ref}>
                                <Squares2X2Icon
                                    className="h-6 w-6 text-gray-500"
                                    onClick={openPopup}
                                />
                            </div>
                        </div>
                        <div ref={ref}>
                            <ProfilePic show={false} openPopup={openPopup} />
                        </div>
                    </div>
                </div>
                <HeaderPopup
                    isOpen={isPopupOpen}
                    handleLogout={handleLogout}
                    items={items}
                    activeItem={activeItem}
                />
            </header>
        );
    }
);
export default Header;
