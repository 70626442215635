// FolderModal.tsx
import React, { useState, useEffect, useRef, useCallback } from 'react';
import api, { endpoints } from '../../utils/api';
import { ArrowUpIcon, FolderIcon } from '@heroicons/react/24/solid';
import styles from './FolderModal.module.scss';

interface Folder {
    id: string;
    name: string;
}

interface FolderModalProps {
    service: string;
    onClose: () => void;
    onSelectFolder: (folderId: string, folderPath: string) => void;
}

const FolderModal: React.FC<FolderModalProps> = ({ service, onClose, onSelectFolder }) => {
    const [folders, setFolders] = useState<Folder[]>([]);
    const [folderStack, setFolderStack] = useState<Folder[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    const currentFolder = folderStack.length > 0 ? folderStack[folderStack.length - 1] : null;

    // Memoize fetchFolders to prevent unnecessary re-creations
    const fetchFolders = useCallback(async () => {
        try {
            setIsLoading(true);
            setError(null);
            const folderReference = currentFolder?.id || '';
            const response = await api.get(`${endpoints.clouds}/${service}/folders`, {
                params: {
                    folderReference: folderReference,
                },
            });
            if (Array.isArray(response.data)) {
                setFolders(response.data);
            } else {
                throw new Error('Invalid data format');
            }
        } catch (error) {
            console.error('Error fetching available folders:', error);
            setError('Failed to load folders.');
            setFolders([]);
        } finally {
            setIsLoading(false);
        }
    }, [currentFolder, service]);

    // useEffect to fetch folders when fetchFolders changes
    useEffect(() => {
        fetchFolders();
    }, [fetchFolders]);

    // Handle modal focus and closing with Escape key
    useEffect(() => {
        modalRef.current?.focus();

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    const handleFolderClick = (folder: Folder) => {
        setFolderStack((prevStack) => [...prevStack, folder]);
    };

    const handleSelectFolder = () => {
        const selectedFolder = currentFolder;
        if (selectedFolder) {
            const folderPath = folderStack.map((f) => f.name).join('/');
            onSelectFolder(selectedFolder.id, folderPath);
            onClose();
        }
    };

    const handleBackClick = () => {
        if (folderStack.length > 0) {
            setFolderStack((prevStack) => prevStack.slice(0, -1));
        }
    };

    // Determine the folder path for display
    const folderPath = folderStack.map((f) => f.name).join('/') || 'Root';

    return (
        <div
            className={styles.modalOverlay}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
            tabIndex={-1}
            ref={modalRef}
        >
            <div className={styles.modal}>
                <div className={styles.modalHeader}>
                    <h3 id="modal-title" className={styles.modalTitle}>
                        Select a Folder from {service}
                    </h3>
                </div>
                <div className={styles.modalContent}>
                    <p className={styles.currentPath}>Current Path: {folderPath}</p>
                    {isLoading ? (
                        <p>Loading folders...</p>
                    ) : error ? (
                        <p>{error}</p>
                    ) : (
                        <ul className={styles.folderList}>
                            {currentFolder && (
                                <li className={styles.folderItem}>
                                    <button
                                        className={styles.folderButton}
                                        onClick={handleBackClick}
                                        aria-label="Go Up"
                                    >
                                        <ArrowUpIcon className={`${styles.iconFolder} ${styles.iconBlue}`} />
                                        <span>Go Up</span>
                                    </button>
                                </li>
                            )}
                            {folders.map((folder) => (
                                <li key={folder.id} className={styles.folderItem}>
                                    <button
                                        className={styles.folderButton}
                                        onClick={() => handleFolderClick(folder)}
                                        aria-label={`Open folder ${folder.name}`}
                                    >
                                        <FolderIcon className={styles.iconFolder} />
                                        <span>{folder.name}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className={styles.modalActions}>
                    <button
                        className={styles.confirmButton}
                        onClick={handleSelectFolder}
                        disabled={!currentFolder}
                        aria-disabled={!currentFolder}
                    >
                        Confirm Selection
                    </button>
                    <button className={styles.closeButton} onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FolderModal;
