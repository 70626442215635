import React, { ChangeEvent, useRef, useState } from "react"
import { Button } from "react-bootstrap"
import api, { endpoints } from "../../utils/api"

interface ConversationSearchProps {
    getConversations: (page: number) => Promise<void>;
}

const ConversationSearch: React.FC<ConversationSearchProps> = ({getConversations}) => {
    const searchObject: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null)
    const [newChatModal, setNewChatModal] = useState(false)
    const [containsComma, setContainsComma] = useState(false)
    const newChatRecipient = useRef<HTMLInputElement>(null)
    const newGroupName = useRef<HTMLInputElement>(null)

    function handleSearchSubmit(){
        // do searching here
        // searchObject.current.value
    }

    const addNewChat = () => {
        setNewChatModal(prev => !prev)
    }

    const handleNewChatSubmit = async () => {
        // check mail for validity
        let mails = newChatRecipient.current?.value.replace(/\s+/g, '').split(",")
        const updatedMails = mails?.filter((ele) => {
            return /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}\b/g.test(ele)
        })
        
            await api.post(endpoints.chats + "/create", { "emails": updatedMails, "name": newGroupName.current?.value})
                .then(() => {
                    newChatRecipient.current!.value = ""
                    setContainsComma(false)
                    setNewChatModal(false)
                    getConversations(1)
                }).catch((error) => {
                    console.error("New chat was not established:", error)
                })
            // tell convoList to update chats (or wait for acceptance?)


    }

    const commaChecker = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        setContainsComma(/,/.test(newValue))
    }


    return(
        <>
            <div id="conversation-search">
                <Button id="add-chat-btn" onClick={addNewChat} variant={newChatModal ? "danger" : "primary"}>{newChatModal ? <i className="bi bi-x-octagon" /> : <i className="bi bi-plus-square" />}</Button>
                <input type="search"
                id="conversation-search-input"
                placeholder="Search Messages"
                ref={searchObject}
                onSubmit={handleSearchSubmit}
                // currently disabled!!!
                disabled />
            </div>
            {newChatModal ? <div id="new-chat-modal">
                <input placeholder="Email(s); multiple separated by commas" type="email" id="new-chat-mail" ref={newChatRecipient} onChange={commaChecker}/>
                {containsComma ? <input placeholder="Group chat name" type="text" id="new-group-chat-name" ref={newGroupName}></input> : null}
                <Button id="new-chat-btn" onClick={handleNewChatSubmit}>Start chat</Button>
                </div> : null}
        </>
    )
}

export default ConversationSearch