import Button, { ButtonProps } from 'react-bootstrap/Button';

interface MUBbtnProps extends ButtonProps {}

const MUBbtn: React.FC<MUBbtnProps> = (props) => {

  return (
    <>
        <Button
            {...props}
        >
        {'Get Answer from MUB'}
        </Button>
    </>
  );
}

export default MUBbtn;