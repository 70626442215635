import React, { Dispatch, SetStateAction, useContext, useRef } from "react"
import { Button } from "react-bootstrap"
import { messageDTO } from "../../models/messages.models"
import AuthenticationContext from "../auth/AuthenticationContext"
import { DateTime } from "luxon"
import WebSocketContext, { WebSocketContextType } from "../contexts/WebSocketContext"


export default function Compose(props: ComposeProps) {    
    const chatIdRef = useRef<string>(props.id)
    const messageRef = useRef<HTMLInputElement>(null)
    const { meRef } = useContext(AuthenticationContext)
    const { sendMessage } = useContext(WebSocketContext) as WebSocketContextType;


    const handleSendMessage = () => {
        if (chatIdRef.current && messageRef.current?.value) {
            sendMessage(chatIdRef.current, messageRef.current.value)

            // write into temp storage and display in messageList
            const newMessage: messageDTO = {
                chatId: chatIdRef.current,
                sender: meRef.current,
                content: messageRef.current.value,
                createdDate: DateTime.now().toString(),
                isRead: true
            }
            props.setMessages([...props.messages, newMessage])

            messageRef.current.value = ""
            focusLatestMessage(500)
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if(event.key === "Enter"){
            event.preventDefault()
            handleSendMessage()
        }
    }

    const focusLatestMessage = async (waiting: number) => {
        setTimeout(() => {props.scrollRef.current!.scrollTop = props.scrollRef.current!.scrollHeight}, waiting)
    }


    return(
        <>
        <div id="compose">
            <input
            type="text"
            id="compose-input"
            ref={messageRef}
            onKeyDown={handleKeyDown}
            placeholder={`Type a message`} />

            <Button onClick={handleSendMessage} id="compose-btn">
                Send
            </Button>
        </div>
        </>
    )
}

interface ComposeProps {
    id: string
    messages: messageDTO[]
    setMessages: Dispatch<SetStateAction<messageDTO[]>>
    scrollRef: React.RefObject<HTMLDivElement>
}