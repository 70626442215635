import React from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import { DateTime } from 'luxon';
import { adjustByteSize, fileDTO } from '../../models/fileModel';

export interface FileInfoModalProps extends ModalProps {
    fileToModal?: fileDTO | null;
}

const FileInfoModal: React.FC<FileInfoModalProps> = ({ fileToModal, ...props }) => {
    return (
        <Modal {...props} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">File Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {fileToModal ? (
                    <ul>
                        <li><b>Name:</b> {fileToModal.name}</li>
                        <li>
                            <b>Date:</b>{' '}
                            {DateTime.fromISO(fileToModal.createdDate!).toLocaleString(
                                DateTime.DATETIME_MED
                            )}
                        </li>
                        <li><b>Size:</b> {adjustByteSize(fileToModal.size!)}</li>
                        <li><b>Owner:</b> {fileToModal.owner}</li>
                        <li><b>Domain:</b> {fileToModal.domain}</li>
                    </ul>
                ) : (
                    <p>No file selected</p>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default FileInfoModal;
