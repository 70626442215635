import { useContext, useEffect, useRef, useState } from 'react';
import AuthenticationContext from './components/auth/AuthenticationContext';
import AppRouter from './components/AppRouter';
import FeedbackToast from './components/elements/FeedbackToast';
import api, { endpoints } from './utils/api';

import './App.scss';
import { User } from './models/auth.models';
import { fileDTO, fileURLModel } from './models/fileModel';
import { getEmptyBlob } from './utils/formDataUtils';
import { WebSocketProvider } from './components/contexts/WebSocketContext';


function App() {

    const [user, setUser] = useState<User | null | undefined>(null)

    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState("")
    const [mode, setMode] = useState("")
    const [duration, setDuration] = useState<number>(0)

    const meRef = useRef<string>("")

    const [foundedFiles, setFoundedFiles] = useState<fileDTO[]>([]);

    const [blobURLState, setBlobURLState] = useState<fileURLModel>(getEmptyBlob())

    const [textSearch, setTextSearch] = useState('');

    useEffect(() => {
        const connect = async () => {
            try {
                const response = await api.get(endpoints.accounts + '/user')
                // authorization
                setIsLoggedIn(true)
                setUser(response.data)
                // fetch own mail
                if (!meRef.current) {
                    meRef.current = response.data.email
                }
            } catch (e) {
                console.error("Failed to connect to the server", e)
                setIsLoggedIn(false);
                setUser(null);
            }
        }
        connect()
    }, [])

    return (
        <AuthenticationContext.Provider
            value={{
                isLoggedIn,
                setIsLoggedIn,
                user,
                setUser,
                meRef,
                success,
                setSuccess,
                message,
                setMessage,
                mode,
                setMode,
                duration,
                setDuration,
                foundedFiles,
                setFoundedFiles,
                blobURLState,
                setBlobURLState,
                textSearch,
                setTextSearch,
            }}>
            <WebSocketProvider>
                <FeedbackToast />
                <AppRouter />
            </WebSocketProvider>
        </AuthenticationContext.Provider>

    )
}

export default App