export default function Confirm(
    onConfirm: any,
    title: string = "Are you sure?",
    confirmButtonText: string = "Delete" // Note: This cannot be used with window.confirm
) {
    // Using window.confirm
    const isConfirmed = window.confirm(title);
    if (isConfirmed) {
        onConfirm();
    }
}
