import { ReactElement, useContext } from "react";
import AuthenticationContext from "./AuthenticationContext";

export default function Authorized(props: authorizedProps) {
    // console.log('Authorized component rendered');
    const { isLoggedIn } = useContext(AuthenticationContext);

    return (
        <>
            {isLoggedIn ? props.authorized : props.notAuthorized}
        </>
    )
}

interface authorizedProps {
    authorized: ReactElement;
    notAuthorized?: ReactElement;
}
