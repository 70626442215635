import {Link} from 'react-router-dom';
import Login from './auth/Login';
import logoBeta from '../resources/logo_beta.png';
import RequestPasswordResetModel from './auth/RequestPasswordResetModel';
import {useState} from 'react';

import '../styles/LoginPage.scss';
import appstoreLogo from '../resources/appstoreLogo.png';
import playstoreLogo from '../resources/playstoreLogo.png';

export default function LoginPage() {
    const [showPasswordReset, setShowPasswordReset] = useState(false)

    const togglePopup = () => {
        setShowPasswordReset(!showPasswordReset)
    }

    return (
        <>
            <div id='login-view'>
                <div id="logo-container">
                    <img src={logoBeta} id="login-logo" alt="logo"/>
                </div>
                <div id="login-container">
                    <Login/>
                    <div id="alternatives-container">
                        <div>
                            <Link to="/register">Register</Link>
                        </div>
                        <div>
                            <u onClick={togglePopup} style={{color: "#176AD6", cursor: "pointer"}}>Forgot Password?</u>
                            {showPasswordReset && <RequestPasswordResetModel closePopup={togglePopup}/>}                        
                        </div>
                    </div>
                </div>
                <div className="download-buttons-container">
                    <a href="https://apps.apple.com/ch/app/myunicbrain/id6689514181" target="_blank" rel="noopener noreferrer">
                        <img src={appstoreLogo} alt="Download on the App Store" className="download-button"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.arssoso.mub_app" target="_blank" rel="noopener noreferrer">
                        <img src={playstoreLogo} alt="Download on Google Play" className="download-button"/>
                    </a>
                </div>
                <div className='footer'>
                    <a href='https://myunicbrain.com/impressum/'>Legal notice</a>
                    <a href='https://myunicbrain.com/nutzungsbedingungen/'>Terms of use</a>
                    <a href='https://myunicbrain.com/datenschutz/'>Privacy policy</a>
                </div>
            </div>
        </>
    )
}