import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import ConversationSearch from "./ConversationSearch";
import ConversationListItem from "./ConversationListItem";
import { DateTime } from "luxon";
import api, { endpoints } from "../../utils/api";
import WebSocketContext, { WebSocketContextType } from "../contexts/WebSocketContext";

interface convoListProps {
    setActiveChat: Dispatch<SetStateAction<{ activeName: string, activeId: string, activeUsers: string[] }>>
    activeChatId: string
}

const ConversationList: React.FC<convoListProps> = ({ setActiveChat, activeChatId }) => {
    const [conversations, setConversations] = useState<{ photo: any; name: string; text: string, updated: DateTime, id: string, unread: number, users: string[] }[]>([])
    const [, setReload] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)
    const [hasMore, setHasMore] = useState<boolean>(true)
    const containerRef = useRef<HTMLDivElement | null>(null)
    const { messageReceivedSignalR, newsCount, setNewsCount } = useContext(WebSocketContext) as WebSocketContextType;

    useEffect(() => {
        getConversations(1)
    }, [messageReceivedSignalR, newsCount])

    const getConversations = async (page: number) => {
        try {
            const response = await api.get(endpoints.chats + "/chats" + "?PageSize=15&Page=" + page)

            if (response.data.length < 15) {
                setHasMore(false)
                // edge case empty array
                if (response.data.length === 0) {
                    return
                }
            }

            const updatedConvos = response.data.map((element: chatUserDTO) => ({
                name: `${element.name}`,
                text: `${element.lastMessage}`,
                updated: DateTime.fromISO(element.updatedDate),
                id: element.id,
                unread: element.unreadMessages,
                users: element.users
            }))
            setConversations(updatedConvos)

            // const totalUnreadMessages = response.data.reduce((total: number, element: any) => total + element.unreadMessages, 0)

        } catch (e) {
            console.error("There were no messages to be retrieved, maaybe there was just an 'error':", e)
        }
    }

    const handleScroll = () => {
        if (containerRef.current) {
            const container = containerRef.current
            if (container.scrollTop + container.clientHeight >= container.scrollHeight && hasMore) {
                setPage((prevPage) => {
                    getConversations(prevPage + 1)
                    return prevPage + 1
                })
            }
        }
    }

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.addEventListener("scroll", handleScroll)
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.removeEventListener("scroll", handleScroll)
            }
        }
    }, [page, hasMore])

    const chooseConversation = (conversation: { photo: any; name: string; text: string, updated: DateTime, id: string, unread: number, users: string[] }) => {
        if (conversation.unread !== 0 && newsCount !== 0) {
            setNewsCount((prev) => {
                if (prev) {
                    return (prev - conversation.unread)
                } else {
                    return conversation.unread
                }
            })

            conversation.unread = 0;
        }
        setTimeout(() => {
            setReload(prev => !prev)
            setActiveChat({ activeName: conversation.name, activeId: conversation.id, activeUsers: conversation.users })
        }, 100)
    }


    return (
        <>
            <div id="conversation-list">
                <ConversationSearch getConversations={getConversations} />
                <div ref={containerRef} id="conversation-list-contacts">
                    {conversations.map(conversation =>
                        <ConversationListItem
                            isActiveChat={activeChatId === conversation.id}
                            key={conversation.id}
                            data={conversation}
                            onClick={() => chooseConversation(conversation)} />
                    )}
                </div>
            </div>
        </>
    )
}

interface chatUserDTO {
    id: string,
    users: string[],
    updatedDate: string,
    name: string,
    lastMessage: string,
    unreadMessages: number
}

export default ConversationList