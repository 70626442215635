import { useContext, useEffect, useState } from "react";
import DisplayErrors from "../elements/DisplayErrors";
import Modal from "react-bootstrap/Modal";
import FileRenameForm from "./FileRenameForm";
import { fileRenameDTO } from "../../models/fileModel";
import api, { endpoints } from "../../utils/api";
import AuthenticationContext from "../auth/AuthenticationContext";


export default function FileRenameModal(props: fileRenameModalProps) {
    const [fileName, setFileName] = useState<string>("")
    const [errors, setErrors] = useState<string[]>([])

    const { setFoundedFiles } = useContext(AuthenticationContext)
    
    useEffect(() => {
        setFileName(props.file.newName)
    }, [])


    async function setNewFilename(inputValue: string) {
        const tailIndex = props.file.newName.lastIndexOf(".")
        const tail = props.file.newName.substring(tailIndex + 1)
        const newName = inputValue + "." + tail

        try {
            await api.put(`${endpoints.files}/rename`, {"fileId": props.file.fileId, "newName": newName})
                .then(() => {
                    setFoundedFiles([])
                    props.handleClose(false)
                })

            // reload data!

        }
        catch (error) {
            if (error && error.response) {
                setErrors(error.response.data)
            }
        }
    }


    return(
        <>
            <Modal show={props.show} onHide={() => props.handleClose(false)} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Rename file</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DisplayErrors errors={errors}></DisplayErrors>
                {fileName ?
                    <FileRenameForm model={{fileId: props.file.fileId, newName: ""}}
                        onSubmit={async (values) => await setNewFilename(values.newName)} 
                        handleClose={props.handleClose}></FileRenameForm>
                : null}
            </Modal.Body>
            </Modal>
        </>
    )
}

interface fileRenameModalProps {
    file: fileRenameDTO;
    show: boolean;
    handleClose(value: boolean) : void;
}