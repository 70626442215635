import React from "react"
import SharedStateContext from "./SharedStateContext"
import { Button } from "react-bootstrap"


export default function FileContextMenu() {
    const { showMenu, position, menuItems, hideContextMenu } = React.useContext(SharedStateContext)

    const style: React.CSSProperties = {
        top: position.y,
        left: position.x,
    }

    const handleItemClick = (action: () => void) => {
        action()
        hideContextMenu()
    }


    return(
        <>
        {showMenu ?
        <div style={style} id="fl-option-btns">
            {menuItems.map((item, index) => (
                <Button key={index} id={item.label} onClick={() => handleItemClick(item.action)} variant={item?.variant}>
                  <i className={`bi ${item?.icon}`}></i>
                    {item.label}
                </Button>
            ))}
        </div> : null}
        </>
    )
}