import { useState } from "react"
import SharedStateContext from "./SharedStateContext"
import FileContextMenu from "./FileContextMenu";


interface ShareFileProviderProps {
    children?: React.ReactNode
}

interface ContextMenuItem {
    label: string;
    action: () => void;
    variant?: string;
    icon?: string
}


// ohh great provider of states and everything between
// holds state of Badge counter, editability & context menu

export default function SharedFileStateProvider({children}: ShareFileProviderProps) {
    const [sharedState, setSharedState] = useState<string[]>([])

    const [showMenu, setShowMenu] = useState(false)
    const [position, setPosition] = useState({ x: 0, y: 0})
    const [menuItems, setMenuItems] = useState<ContextMenuItem[]>([])

    const showContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        setPosition({x: e.clientX, y: e.clientY})
        setShowMenu(true)
    }

    const hideContextMenu = () => {
        setShowMenu(false)
    }


    return(
        <div onContextMenu={showContextMenu} onClick={hideContextMenu} onMouseLeave={hideContextMenu}>
            <SharedStateContext.Provider value={{ sharedState, setSharedState, showMenu, position, menuItems, showContextMenu, hideContextMenu, setMenuItems}}>
                {children}
                <FileContextMenu />
            </SharedStateContext.Provider>
        </div>
    )
}