import * as Yup from 'yup';

export const emailValidation = Yup.string()
    .required('This field is required')
    .email('You have to insert a valid email');

export const passwordValidation = Yup.string()
    .required("Password is required")
    .min(8, "Password is too short")
    .matches(/[0-9]/, "Password must contain at least one number")
    .matches(/[a-zA-Z]/, "Password must contain at least one letter")
    .matches(/(?=.*[^\da-zA-Z])/, "Password must contain at least one special character");

export const confirmPasswordValidation = Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref('password'), null!], "Passwords must match");

export const currentPasswordValidation = Yup.string().required("Current password is required");