import {fileCreationDTO, fileURLModel} from "../models/fileModel";

export function convertFileToFormData(file: fileCreationDTO): FormData {
    const formData = new FormData();

    formData.append('name', file.name);

    if (file.blob) {
        formData.append('blob', file.blob);
    }

    return formData;
}

export function getEmptyBlob(): fileURLModel {
    return {
        fileURL: '', type: '', blob: null
    }
} 