import { Link } from 'react-router-dom';
import Register from './auth/Register';
import logoBeta from '../resources/logo_beta.png';

import '../styles/LoginPage.scss';


export default function RegisterPage() {
    return (
    <>
    <div id='register-view'>
        <div id="logo-container">
            <img src={logoBeta} id="login-logo" alt="logo" />
        </div>
        <div id="register-container">
            <Register />
            <div id="alternatives-container">
                <Link to="/login">Login</Link>
            </div>
        </div>
    </div>
    </>
    )
}