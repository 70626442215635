import { EventEmitter } from 'events';

export interface ToastMessage {
    message: string;
    mode: string;
    duration: number;
}

class ToastService extends EventEmitter {
    static instance: ToastService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!ToastService.instance) {
            ToastService.instance = new ToastService();
        }
        return ToastService.instance;
    }

    showToast(message: string, mode: string = 'info', duration: number = 3000) {
        const toastMessage: ToastMessage = { message, mode, duration };
        this.emit('showToast', toastMessage);
    }
}

export default ToastService.getInstance();
