import React, { useEffect, useState } from "react";
import DisplayErrors from "../elements/DisplayErrors";
import { ProgressBar } from "react-bootstrap";
import useFeedback from "../elements/customHooks/useFeedback";
import api, { endpoints } from "../../utils/api";

import '../../styles/FileUpload.scss';
import { File } from '../DashboardPage'
import { AxiosResponse } from "axios";


export default function DragDropFile({setLastFiles}: any) {
    const [dragActive, setDragActive] = useState(false)
    const [errors, setErrors] = useState<string[]>([])
    const [uploadProgess, setUploadProgess] = useState<number>(0)
    const inputRef = React.useRef<HTMLInputElement>(null)
    const { done, error } = useFeedback()


    useEffect(() => {
    }, [inputRef])

    async function fetchData() {
      api
      .get(endpoints.files, {params: {
          page: 1,
          recordsPerPage: 5,
          sortBy: "CreatedDate",
          sortOrder: "dsc",
      }})
      .then((response: AxiosResponse<File[]>) => {
        setLastFiles(response?.data?.slice(0, 4));
      });

    }

    async function enterFiles(files: FileList) {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('data', files[i])
        }
        try {
            await api({
                method: 'post',
                url: endpoints.files + '/upload',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total!)
                    setUploadProgess(progress)}
            })
            setLastFiles && await fetchData()
        }
        catch (error) {
            if (error && error.response) {
                setErrors(error.response.data)
            }
        }
        setUploadProgess(0)
        done("File upload successful", "success", 3000)
    }

    // handle drag events
    const handleDrag = function (e: React.DragEvent) {
        e.preventDefault()
        e.stopPropagation()
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true)
        } else if (e.type === "dragleave") {
            setDragActive(false)
        }
    }

    // triggers when file is dropped
    const handleDrop = function (e: React.DragEvent) {
        e.preventDefault()
        e.stopPropagation()
        setDragActive(false)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            console.log(e.dataTransfer.files[0])
            enterFiles(e.dataTransfer.files)
        }
    }

    // triggers when file is selected with click
    const handleChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        if (e.target.files && e.target.files[0]) {
            console.log(e.target.files[0])
            enterFiles(e.target.files)
        }
    }

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        if (inputRef.current) {
            inputRef.current.click()
        }
    }

    return (
        <>
            <DisplayErrors errors={errors} />
            <form id="form-file-upload" className="formFileUpload" onDragEnter={(e) => handleDrag(e)} onSubmit={(e) => e.preventDefault()}>
                <input ref={inputRef} type="file" id="input-file-upload" className="inputFileUpload" multiple={true} onChange={handleChange} />
                <label id="label-file-upload" htmlFor="input-file-upload" className={`labelFileUpload ${dragActive ? "dragActive" : ""}`}>
                    <div id="file-upload-text">
                        <p>Drag and drop your file here or,</p>
                        <button className="fileUpload" onClick={onButtonClick}>Upload a file</button>
                    </div>
                </label>
                {dragActive && <div id="drag-file-element" className="dragFileElement" onDragEnter={(e) => handleDrag(e)} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                {uploadProgess !== 0 ? <ProgressBar id="upload-progress" min={0} max={100} label={`${uploadProgess}%`} variant="info" striped animated now={uploadProgess} /> : null}
            </form>
        </>
    )
}