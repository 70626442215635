import {Button} from 'react-bootstrap';
import {Dispatch, ReactElement, SetStateAction, useContext, useEffect, useState} from 'react';
import {AxiosResponse} from 'axios';
import Confirm from './Confirm';
import GenericList from './GenericList';
import Pagination from './Pagination';
import PaginationRecordsPerPageSelect from './PaginationRecordsPerPageSelect';
import {DateTime} from 'luxon';
import SharedStateContext from '../files/SharedStateContext';
import AuthenticationContext from '../auth/AuthenticationContext';
import api from '../../utils/api';

export default function EntityIndex<T>(props: entityIndexProps<T>) {
    const {sharedState} = useContext(SharedStateContext);
    const {textSearch} = useContext(AuthenticationContext);

    const [entities, setEntities] = useState<T[]>([]);
    const [totalAmountOfPages, setTotalAmountOfPages] = useState(0);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const [reloading, setReloading] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState(props.sortBy);
    const [sortOrder, setSortOrder] = useState(props.sortOrder);

    useEffect(() => {
        if (!props.foundedFiles?.length && !textSearch) {
            loadData();
        } else {
            setEntities(props.foundedFiles || []);
        }
    }, [page, recordsPerPage, reloading, props.foundedFiles, sharedState, props.newLoadFile, textSearch, sortBy, sortOrder]);

    function loadData() {
        api.get(props.url, {
            params: {page, recordsPerPage, sortBy, sortOrder, requestPending: props.requestPending},
        }).then((response: AxiosResponse<T[]>) => {
            const totalAmountOfRecords = parseInt(response.headers['totalamountofrecords'], 10);
            setTotalAmountOfPages(Math.ceil(totalAmountOfRecords / recordsPerPage));
            setEntities(response.data);

            if (props.requestAmount !== undefined) {
                props.requestAmount(response.data.length);
            }
        });
    }

    async function deleteEntity(id: string) {
        try {
            await api.delete(`${props.url}/${id}`);
            loadData();
        } catch (error) {
            if (error && error.response) {
                console.error(error.response.data);
            }
        }
    }

    const buttons = (id: string, editURL: string) => (
        <>
            <Button variant="danger" onClick={() => Confirm(() => deleteEntity(id))}>
                Delete
            </Button>
        </>
    );

    return (
        <>
            <GenericList list={entities}>
                {props.children(entities!, buttons, (key: string, order: string) => {
                    setSortBy(key);
                    setSortOrder(order);
                })}
            </GenericList>

            {entities.length > 0 && totalAmountOfPages > 1 && (
                <div id="pagination-container">
                    <Pagination
                        currentPage={page}
                        totalAmountOfPages={totalAmountOfPages}
                        onChange={(newPage) => setPage(newPage)}
                    />
                </div>
            )}
        </>
    );
}

interface entityIndexProps<T> {
    entityName: string;
    title: string;
    url: string;
    requestPending?: boolean;
    requestAmount?: Dispatch<SetStateAction<number>>;

    children(entities: T[], buttons: (id: string, editUrl: string) => ReactElement, sortItems: (key: string, order: string) => void): ReactElement;

    foundedFiles?: T[];
    newLoadFile?: boolean;
    sortBy: string;
    sortOrder: string;
}
