import { Button } from "react-bootstrap";
import { fileDTO } from "../../models/fileModel"
import { useContext, useEffect, useState } from "react";
import Confirm from "../elements/Confirm";
import FileShareModal from "./FileShareModal";
import SharedStateContext from "./SharedStateContext";
import useClickOutside from "./useClickOutside";
import FileRenameModal from "./FileRenameModal";
import api, { endpoints } from "../../utils/api";
import AuthenticationContext from "../auth/AuthenticationContext";


interface subFileProps {
    file: fileDTO;
    isOpen: boolean;
    toggleMenu: () => void;
}


export default function FileSubbuttons({file, isOpen, toggleMenu}: subFileProps) {
    const [showShareModal, setShowShareModal] = useState<boolean>(false)
    const [showRenameModal, setShowRenameModal] = useState<boolean>(false)
    const { setMenuItems } = useContext(SharedStateContext)
    const { setFoundedFiles } = useContext(AuthenticationContext)
    const { isOpen: isActive, setIsActive, ref} = useClickOutside()

    // see documentation for this mess :D
    // basically whenever you click outside of a pressed button
    // it collapses the menu via a custom hook
    useEffect(() => {
        setIsActive(isOpen)
    }, [isOpen, setIsActive])

    useEffect(() => {
        setMenuItems([
            { label: "Download", action: () => downloadFile(file), variant:"primary", icon: "bi-download"},
            { label: "Share", action: () => setShowShareModal(true), variant:"primary", icon: "bi-share-fill"},
            { label: "Rename", action: () => setShowRenameModal(true), variant:"light", icon: "bi-vector-pen"},
            { label: "Delete", action: () => Confirm(() => deleteEntity(file.id)), variant:"danger", icon: "bi-trash-fill"},
        ])
    }, [])


    function downloadFile(file: fileDTO) {
        api({
            url: `${endpoints.files}/download/${file.id}`,
            method: 'GET',
            responseType: "blob"
        })
            .then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data)

                // create anachor HTLM element with href to file & click
                const link = document.createElement('a')
                link.href = href
                link.setAttribute('download', file.name)
                document.body.appendChild(link)
                link.click()

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link)
                URL.revokeObjectURL(href)
            })
    }

    async function deleteEntity(id: string) {
        try {
            await api.delete(endpoints.files + `/${id}`)
                .finally(() => {
                    setFoundedFiles([])
                })

        }
        catch (error) {
            if (error && error.response) {
                console.error(error.response.data)
            }
        }
    }


    return(
        <>
            <div ref={ref}>
                <Button
                    variant="secondary"
                    id="fl-options"
                    onClick={toggleMenu}>
                        <i className="bi bi-three-dots" />
                </Button>
                {isActive ?
                    <div id="fl-option-btns">
                        <Button variant="primary" onClick={() => downloadFile(file)}><i className="bi bi-download"></i> Download</Button>
                        <Button variant="primary" onClick={() => setShowShareModal(true)}><i className="bi bi-share-fill"></i> Share</Button>
                        <Button variant="light" onClick={() => setShowRenameModal(true)}><i className="bi bi-vector-pen"></i> Rename</Button>
                        <Button variant="danger" onClick={() => Confirm(() => deleteEntity(file.id))}><i className="bi bi-trash-fill"></i> Delete</Button>
                    </div> : null}
            </div>

            {showShareModal ?
                <FileShareModal
                show={showShareModal}
                handleClose={(value) => setShowShareModal(value)}
                entity={{ "fileId": file.id, "email": "" }} />
            : null}

            {showRenameModal ?
                <FileRenameModal
                show={showRenameModal}
                handleClose={(value) => setShowRenameModal(value)}
                file={{"fileId": file.id, "newName": file.name}} />
            : null}
        </>
    )
}