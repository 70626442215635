import { useContext, useEffect } from "react";
import AuthenticationContext from "../../auth/AuthenticationContext";

type ToastMode = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';

interface UseFeedbackReturn {
    done: (message: string, mode?: ToastMode, duration?: number) => void;
    error: (message: string) => void;
}

const useFeedback = (): UseFeedbackReturn => {
    const { setSuccess, setMessage, setMode, setDuration } = useContext(AuthenticationContext);

    const done = (message: string, mode: ToastMode = 'success', duration: number = 3000) => {
        setMessage(message);
        setMode(mode);
        setDuration(duration);
        setSuccess(true);
    };

    const error = (message: string) => {
        setMessage(message);
        setMode("danger");
        setDuration(3000);
        setSuccess(true);
    };

    return { done, error };
};

export default useFeedback;
