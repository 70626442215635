import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from "formik";
import Button from "react-bootstrap/Button";
import FormTextInput from "../elements/FormTextInput";
import { confirmPasswordValidation, passwordValidation } from '../../validations';

export default function ResetPasswordForm(props: ResetPasswordFormProps) {

    let validationObject: any = {
        password: passwordValidation,
        confirmPassword: confirmPasswordValidation
    };
    
    if (props.changePassword) {
        validationObject.currentPassword = Yup.string().required("Current password is required");
    }
    
    const validationSchema = Yup.object().shape(validationObject);

    return (
        <Formik
            initialValues={{
                currentPassword: '',
                password: '',
                confirmPassword: ''
            }}
            validationSchema={validationSchema}
            onSubmit={props.onSubmit}>
            {(formikProps) => (
                <Form>
                    {props.changePassword && <FormTextInput name="currentPassword" displayName="Current Password" type="password" />}
                    <FormTextInput name="password" displayName="New Password" type="password" />
                    <FormTextInput name="confirmPassword" displayName="Verify Password" type="password" />

                    <Button variant="primary" type="submit" disabled={formikProps.isSubmitting}>Change Password</Button>
                    <Button variant="secondary ms-2" onClick={() => props.handleClose()}>Cancel</Button>
                </Form>
            )}
        </Formik>
    )
}

interface ResetPasswordFormProps {
    onSubmit(values: PasswordResetFormValues, actions: FormikHelpers<PasswordResetFormValues>): void;
    handleClose(): void;
    changePassword?: boolean;
}

interface PasswordResetFormValues {
    currentPassword: string; 
    password: string;
    confirmPassword: string;
}