// Clouds.tsx
import React, { useState, useEffect } from 'react';
import '../styles/CloudsPage.scss';
import Layout from './Layout';
import api, { endpoints } from '../utils/api';
import { useLocation } from 'react-router-dom';
import { CheckCircleIcon, PlusIcon, FolderIcon, TrashIcon } from '@heroicons/react/24/solid';
import FolderModal from './exfiles/FolderModal';
import useFeedback from "./elements/customHooks/useFeedback";
import { Modal, Button, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap';

interface FolderInfo {
    folderId: string;
    provider: string;
    folderPath: string;
    fileCount: number;
}

const Clouds: React.FC = () => {
    const [storageProvider, setStorageProvider] = useState<string[]>([]);
    const [folders, setFolders] = useState<{ [key: string]: FolderInfo[] }>({});
    const [modalProvider, setModalProvider] = useState<string | null>(null);
    const [confirmAction, setConfirmAction] = useState<{
        type: 'disconnect' | 'deleteFolder' | null;
        provider?: string;
        folderId?: string;
        folderPath?: string;
    }>({ type: null });
    const { done } = useFeedback();

    const location = useLocation();

    const [isLoading, setIsLoading] = useState<boolean>(true); // Loading state

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true); // Start loading

            try {
                await Promise.all([fetchConnectedStorageProviders(), fetchTrackedFolders()]);
            } catch (error) {
                console.error('Error fetching initial data:', error);
                // Optionally, set an error state here to display an error message to the user
            } finally {
                setIsLoading(false); // Stop loading
            }
        };

        fetchData();

        const params = new URLSearchParams(location.search);
        const storageProviderParam = params.get('provider');

        if (storageProviderParam) {
            setStorageProvider((prevProviders) => {
                if (!prevProviders.includes(storageProviderParam)) {
                    return [...prevProviders, storageProviderParam];
                }
                return prevProviders;
            });
        }
    }, [location]);

    const fetchConnectedStorageProviders = async () => {
        const response = await api.get(endpoints.clouds + '/connected-providers');
        setStorageProvider(response.data);
    };

    const fetchTrackedFolders = async () => {
        const response = await api.get(endpoints.clouds + '/tracked-folders');
        // Assuming response structure includes provider information
        const folderData = response.data.reduce((acc: { [key: string]: FolderInfo[] }, folder: FolderInfo) => {
            if (!acc[folder.provider]) acc[folder.provider] = [];
            acc[folder.provider].push({
                folderId: folder.folderId,
                folderPath: folder.folderPath,
                fileCount: folder.fileCount,
                provider: folder.provider
            });
            return acc;
        }, {});
        setFolders(folderData);
    };

    const connectProvider = (provider: string) => {
        window.location.href = endpoints.clouds + `/link/${provider}`;
    };

    const addFolder = (provider: string, folder: FolderInfo) => {
        setFolders((prevFolders) => ({
            ...prevFolders,
            [provider]: [...(prevFolders[provider] || []), folder],
        }));
    };

    const handleFolderSelect = (provider: string, folderId: string, folderPath: string) => {
        api
            .post(`${endpoints.clouds}/${provider}/track-folder`, {
                folderId,
                folderPath,
            })
            .then((response) => {
                const fileCount = response.data.fileCount || 0;
                addFolder(provider, { folderId, folderPath, fileCount, provider });
                done("Folder tracking initiated. Indexing is in progress.", "success", 3000);
            })
            .catch((error) => {
                console.error('Failed to track folder:', error);
            });

        setModalProvider(null);
    };

    const confirmDeleteFolder = (provider: string, folderId: string, folderPath: string) => {
        setConfirmAction({ type: 'deleteFolder', provider, folderId, folderPath });
    };

    const handleDeleteFolder = () => {
        const { provider, folderId, folderPath } = confirmAction;
        if (!provider || !folderId) return;

        setFolders((prevFolders) => ({
            ...prevFolders,
            [provider]: prevFolders[provider].filter((folder) => folder.folderId !== folderId),
        }));

        api
            .post(`${endpoints.clouds}/${provider}/untrack-folder`, {
                folderId,
            })
            .then(() => {
                done("Folder untracked successfully", "success", 3000);
            })
            .catch((error) => {
                console.error('Failed to untrack folder:', error);
            });

        setConfirmAction({ type: null });
    };

    const confirmDisconnectProvider = (provider: string) => {
        setConfirmAction({ type: 'disconnect', provider });
    };

    const handleDisconnectProvider = () => {
        const { provider } = confirmAction;
        if (!provider) return;

        // Remove provider from state
        setStorageProvider((prevProviders) =>
            prevProviders.filter((p) => p !== provider)
        );

        // Remove folders for the provider
        setFolders((prevFolders) => {
            const newFolders = { ...prevFolders };
            delete newFolders[provider];
            return newFolders;
        });

        // Call backend API to handle disconnection
        api
            .post(`${endpoints.clouds}/revoke/${provider}`)
            .then(() => {
                done(`Disconnected from ${provider} successfully`, "success", 3000);
            })
            .catch((error) => {
                console.error('Failed to disconnect provider:', error);
            });

        setConfirmAction({ type: null });
    };

    const providers = [
        { name: 'OneDrive', available: true },
        { name: 'GoogleDrive', available: false },
        { name: 'Dropbox', available: false },
    ];

    return (
        <Layout>
            <div className="clouds-page">
                <h3>Connect External Services</h3>

                {isLoading ? (
                    <div className="loading-container">
                        <Spinner animation="border" role="status">
                        </Spinner>
                    </div>
                ) : (
                    <div className="provider-grid">
                        {providers.map((service) => (
                            <div className="service-item" key={service.name}>
                                <div className="service-header">
                                    <div className="logo-container">
                                        <img src={`/images/${service.name.toLowerCase()}-100.png`} alt={service.name} />
                                    </div>
                                    <h4 data-bs-toggle="tooltip" data-bs-placement="top" title={service.name}>
                                        {service.name}
                                    </h4>
                                    {storageProvider.includes(service.name) && (
                                        <button
                                            className="disconnect-button btn btn-danger btn-sm"
                                            onClick={() => confirmDisconnectProvider(service.name)}
                                            aria-label={`Disconnect ${service.name}`}
                                            data-bs-toggle="modal"
                                            data-bs-target="#disconnectModal"
                                        >
                                            Disconnect
                                        </button>
                                    )}
                                </div>
                                <div className="service-content">
                                    {storageProvider.includes(service.name) ? (
                                        <>
                                            <div className="connected-container">
                                                <CheckCircleIcon className="icon icon-green small-icon" />
                                                <span className="connected-text">Connected</span>
                                            </div>
                                            {folders[service.name]?.length > 0 ? (
                                                <ul className="folder-list">
                                                    {folders[service.name].map((folder) => (
                                                        <li key={folder.folderId}>
                                                            <span className="folder-item">
                                                                <FolderIcon className="icon icon-folder" />
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id={`tooltip-folder-${folder.folderId}`}>
                                                                            {folder.folderPath}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span className="folder-path" data-bs-toggle="tooltip" title={folder.folderPath}>
                                                                        {folder.folderPath.length > 25
                                                                            ? `${folder.folderPath.substring(0, 25)}...`
                                                                            : folder.folderPath}
                                                                    </span>
                                                                </OverlayTrigger>
                                                                <span className="file-count">({folder.fileCount} files)</span>
                                                                <button
                                                                    className="delete-folder-button btn btn-link p-0 ms-2"
                                                                    onClick={() => confirmDeleteFolder(service.name, folder.folderId, folder.folderPath)}
                                                                    aria-label="Delete Folder"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#deleteFolderModal"
                                                                >
                                                                    <TrashIcon className="icon icon-red" />
                                                                </button>
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p>No folders added yet.</p>
                                            )}
                                            <button
                                                className="add-folder-button btn btn-primary btn-sm"
                                                onClick={() => setModalProvider(service.name)}
                                            >
                                                <PlusIcon className="icon icon-blue" />
                                                <span>Add Folder</span>
                                            </button>
                                        </>
                                    ) : !service.available ? (
                                        <div className="service-overlay coming-soon" title="Coming Soon">
                                            <button className="connect-button disabled-button btn btn-secondary btn-sm" disabled>
                                                Connect {service.name}
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            className={`connect-button btn btn-primary btn-sm`}
                                            onClick={() => connectProvider(service.name)}
                                        >
                                            Connect {service.name}
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {/* Folder selection modal */}
                {modalProvider && (
                    <FolderModal
                        service={modalProvider}
                        onClose={() => setModalProvider(null)}
                        onSelectFolder={(folderId, folderPath) =>
                            handleFolderSelect(modalProvider!, folderId, folderPath)
                        }
                    />
                )}

                {/* Disconnect Confirmation Modal */}
                <Modal
                    show={confirmAction.type === 'disconnect'}
                    onHide={() => setConfirmAction({ type: null })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Disconnect Provider</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {`Are you sure you want to disconnect from ${confirmAction.provider}?`}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setConfirmAction({ type: null })}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={handleDisconnectProvider}>
                            Disconnect
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* Delete Folder Confirmation Modal */}
                <Modal
                    show={confirmAction.type === 'deleteFolder'}
                    onHide={() => setConfirmAction({ type: null })}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Folder</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {`Are you sure you want to delete the folder "${confirmAction.folderPath}"?`}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setConfirmAction({ type: null })}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={handleDeleteFolder}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </Layout>
    );

};

export default Clouds;
