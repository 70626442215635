import ConversationList from "./ConversationList";
import MessageList from "./MessageList";
import { useState } from "react";

import '../../styles/Messages.scss';

export interface chatUserModel {
    activeName: string, 
    activeId: string, 
    activeUsers: string[]
}

export default function Messenger() {
    const [activeChat, setActiveChat] = useState<chatUserModel>({activeName: "", activeId: "", activeUsers: []})
    

    return(
        <>
        <div>
            <div id="messenger">
                <div id="contacts">
                    <ConversationList setActiveChat={setActiveChat} activeChatId={activeChat.activeId}/>
                </div>
                <div id={activeChat.activeId === "" ? "empty messages": "messages"}>
                    {activeChat.activeId === "" ? null : <MessageList key={activeChat.activeId} name={activeChat.activeName} chatId={activeChat.activeId} participants={activeChat.activeUsers}/>}
                </div>
            </div>
        </div>
        </>
    )
}