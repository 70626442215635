import { userCredentials } from "../../models/auth.models";
import { useContext, useEffect, useState } from "react";
import AuthForm from "./AuthForm";
import DisplayErrors from "../elements/DisplayErrors";
import { useNavigate, useLocation } from "react-router-dom";
import AuthenticationContext from "./AuthenticationContext";
import api, { endpoints } from "../../utils/api";
import PasswordResetModal from "./PasswordResetModal";
import useFeedback from "../elements/customHooks/useFeedback";
import axios from 'axios';

export default function Login() {
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthenticationContext);

    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [emailFromUrl, setEmailFromUrl] = useState<string | null>(null);
    const [tokenFromUrl, setTokenFromUrl] = useState<string | null>(null);

    const { done } = useFeedback();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        const emailConfirmationStatus = searchParams.get('emailConfirmed');
        if (emailConfirmationStatus === 'true') {
            done("Email confirmed", "success", 3000);
        }

        const emailFromQuery = searchParams.get('email');
        const tokenFromQuery = searchParams.get('token');

        if (emailFromQuery && tokenFromQuery) {
            setEmailFromUrl(emailFromQuery);
            setTokenFromUrl(tokenFromQuery);
            setShowResetPasswordModal(true);
        }

    }, [location.search]);

    const handleCloseResetPasswordModal = () => {
        setShowResetPasswordModal(false);
        navigate('/login', { replace: true }); // Clean up URL parameters
    };

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn, navigate]);

    async function login(credentials: userCredentials) {
        try {
            setErrors([]);
            const response = await api.post(endpoints.accounts + '/login', credentials);
            if (response.status === 200) {
                setIsLoggedIn(true);
                navigate('/');
            }
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                setErrors([error.message || "An error occurred. Please try again."]);
            } else {
                setErrors(["An unexpected error occurred. Please try again."]);
            }
        }
    }

    return (
        <>
            {showResetPasswordModal ?
                <PasswordResetModal
                    email={emailFromUrl || ''}
                    token={tokenFromUrl || ''}
                    handleClose={handleCloseResetPasswordModal}
                    show={showResetPasswordModal} />
                :
                <div id="login">
                    <h3>Login</h3>
                    <AuthForm
                        model={{ email: '', password: '' }}
                        onSubmit={async values => await login(values)} />
                    <DisplayErrors errors={errors} />
                </div>
            }
        </>
    );
}
