import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from "formik";
import { Button } from 'react-bootstrap';
import FormTextInput from '../elements/FormTextInput';
import { userCredentials } from "../../models/auth.models";
import { confirmPasswordValidation, emailValidation, passwordValidation } from '../../validations';


export default function AuthForm(props: authFormProps) {

    let validationObject: any = {
        email: Yup.string().required('Email is required'),
        password: Yup.string().required("Password is required")
    };
    
    if (props.isRegister) {
        validationObject.email = emailValidation;
        validationObject.password = passwordValidation;
        validationObject.confirmPassword = confirmPasswordValidation;
    }
    
    const validationSchema = Yup.object().shape(validationObject);    
    

    return (
        <Formik
            initialValues={props.model}
            onSubmit={props.onSubmit}
            validationSchema={validationSchema}>
            {formikProps => (
                <Form>
                    <FormTextInput name={'email'} displayName={'Email'} />
                    <FormTextInput name={'password'} displayName={'Password'} type="password" />
                    {props.isRegister ?
                        <>
                            <FormTextInput name={'confirmPassword'} displayName={'Confirm Password'} type="password" />
                        </> :
                        null
                    }
                    <div className="d-grid gap-2">
                        <Button variant="primary" size="lg" type="submit" disabled={formikProps.isSubmitting}>{props.isRegister ? 'Register' : 'Login'}</Button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

interface authFormProps {
    model: userCredentials;
    isRegister?: boolean;
    onSubmit(values: userCredentials, actions: FormikHelpers<userCredentials>): void;
}